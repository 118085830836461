import React from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function App() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };

    return (
    <div className={classes.root}>
      <Button variant="contained" color="secondary" onClick={handleClick}>
        Do not press this button
      </Button>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
                Please do not press this button again.
            </Alert>
        </Snackbar>
    </div>
  );
}

export default App;
